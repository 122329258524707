import FruskacCrni from '../assets/fruvin-fruskac-crni.png';
import FruskacBeli from '../assets/fruvin-fruskac-beli.png';
import FruskacRose from '../assets/fruvin-fruskac-rose.png';
import FruskacCrni2L from '../assets/fruvin-fruskac-crni-2l.png';
import FruskacBeli2L from '../assets/fruvin-fruskac-beli-2l.png';
import FruskacRose2L from '../assets/fruvin-fruskac-rose-2l.png';
import WineListPage from '../components/molecules/WineListPage/WineListPage';
import BackgroundImage from '../assets/fruvin-fruskac-bg.jpeg';
import FruskacLogo from '../assets/fruvin-fruskac.svg';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

const Fruskac = () => {
    const intl = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const wines = [
        {
            id: 9,
            image: FruskacCrni,
            name: <FormattedMessage id='fruskac__red_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__red_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__red_liter' defaultMessage="Missing translation value" />,
            urlName: 'crveni-fruskac'
        },
        {
            id: 10,
            image: FruskacBeli,
            name: <FormattedMessage id='fruskac__white_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__white_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__white_liter' defaultMessage="Missing translation value" />,
            urlName: 'beli-fruskac'
        },
        {
            id: 11,
            image: FruskacRose,
            name: <FormattedMessage id='fruskac__rose_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__rose_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__rose_liter' defaultMessage="Missing translation value" />,
            urlName: 'roze-fruskac'
        },
        {
            id: 12,
            image: FruskacCrni2L,
            name: <FormattedMessage id='fruskac__red_2l_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__red_2l_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__red_2l_liter' defaultMessage="Missing translation value" />,
            urlName: 'crveni-fruskac-2l'
        },
        {
            id: 13,
            image: FruskacBeli2L,
            name: <FormattedMessage id='fruskac__white_2l_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__white_2l_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__white_2l_liter' defaultMessage="Missing translation value" />,
            urlName: 'beli-fruskac-2l'
        },
        {
            id: 14,
            image: FruskacRose2L,
            name: <FormattedMessage id='fruskac__rose_2l_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='fruskac__rose_2l_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='fruskac__rose_2l_liter' defaultMessage="Missing translation value" />,
            urlName: 'roze-fruskac-2l'
        }
    ];

    return (
        <>
            <Helmet>
                <title>Fruškać | Fruškogorski vinogradi</title>
                <meta name="description" content="Otkrijte Fruškać vina iz vinarije Fruškogorski vinogradi na Fruškoj gori. Birajte između belih, crvenih i roze vina, idealnih za svakodnevne prilike, koja donose svežinu i vrhunski kvalitet po pristupačnim cenama." />
            </Helmet>
            <WineListPage
                type={'fruskac'}
                backgroundImage={BackgroundImage}
                descriptionText={intl.formatMessage({ id: 'fruskac__description', defaultMessage: 'Missing translation' })}
                wineLogo={FruskacLogo}
                wines={wines}
            />
        </>
    );
}

export default Fruskac;