import styled from "styled-components";
import CoverImage from "../assets/fruvin-contact-cover.jpg";
import breakpoints from "../breakpoints";
import { ReactComponent as MapLocation } from "../assets/map-location.svg";
import { ReactComponent as Email } from "../assets/email.svg";
import { ReactComponent as Telephone } from "../assets/telephone.svg";
import { useEffect, useState } from "react";
import MapWithLocation from "../components/atoms/MapWithLocation/MapWithLocation";
import ImageSkeleton from "../components/atoms/Skeletons/ImageSkeleton/ImageSkeleton";
import { Helmet } from "react-helmet-async";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  flex-direction: column;
  position: relative;

  a {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (min-width: ${breakpoints.xxl}) {
    flex-direction: row;
    padding: 3rem 5%;
  }
`;

const FlexBoxColumn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid #252121;
  border-radius: 10%;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  width: 300px;
  height: 300px;

  margin-top: 50px;

  @media only screen and (min-width: ${breakpoints.xxsm}) {
    width: 350px;
  }
`;

const EmailIcon = styled(Email)`
  position: absolute;
  top: -35px;
  z-index: 1;
  background-color: white;
`;

const TelephoneIcon = styled(Telephone)`
  position: absolute;
  top: -35px;
  z-index: 1;
  background-color: white;
`;

const MapLocationIcon = styled(MapLocation)`
  position: absolute;
  top: -35px;
  z-index: 1;
  background-color: white;
`;


const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 1.5 / 1;

  @media only screen and (min-width: ${breakpoints.md}) {
    aspect-ratio: 1.5 / 1;
  }
`;

const CoverImageStyled = styled.img<{ loaded: boolean }>`
  width: 100%;
  height: 100%;

  display: ${(props) => (props.loaded ? `block` : `none`)};
`;

const Contact = () => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact | Fruškogorski vinogradi</title>
        <meta name="description" content="Kontaktirajte nas za sve informacije o našim vinima, obilascima i degustacijama. Naša lokacija i kontakt detalji dostupni su na ovoj stranici. Vina sa Fruške gore. Degustacija vina." />
      </Helmet>
      <Wrapper>
        <picture onLoad={handleLoad}>
          <source srcSet={`${CoverImage}`} media="(min-width: 768px)" />
          <source srcSet={`${CoverImage}`} media="(max-width: 767px)" />
          <CoverImageStyled
            src={`${CoverImage}`}
            alt={"Wonderful vineyard"}
            loaded={loaded}
          />
        </picture>
        <ImageSkeleton loaded={loaded} />
      </Wrapper>
      <FlexBox>
        <FlexBoxColumn>
          <EmailIcon width={64} height={64} />
          <a href="mailto:info@fruvin.com">info@fruvin.com</a>
        </FlexBoxColumn>
        <FlexBoxColumn>
          <MapLocationIcon width={64} height={64} />
          <span>
            Dunavska 5, <br />
            Banoštor, Srbija
          </span>
        </FlexBoxColumn>
        <FlexBoxColumn>
          <TelephoneIcon width={64} height={64} />
          <a href="tel:+381648890023">+381 (0) 64/889-0023</a> <br />
          <a href="tel:+381216613500">+381 (0) 21/6613-500</a>
        </FlexBoxColumn>
      </FlexBox>
      <MapWithLocation />
    </>
  );
};

export default Contact;
