import CoverImage from '../assets/fruvin-about-us-cover.webp';
import CoverImageMobile from '../assets/fruvin-about-us-cover-mobile.webp';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import breakpoints from '../breakpoints';
import ImageSkeleton from '../components/atoms/Skeletons/ImageSkeleton/ImageSkeleton';
import { Helmet } from 'react-helmet-async';

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 5%;
`;

const Text = styled.div`
    font-size: 13px;
    line-height: 20px;
    white-space: pre-line;
    text-align: justify;
`;

const Wrapper = styled.div`
    width: 100%;
    aspect-ratio: 2 / 1;

    @media only screen and (min-width: ${breakpoints.md}) {
        aspect-ratio: 16 / 9;
    }
`;

const CoverImageStyled = styled.img<{loaded: boolean}>`
    width: 100%;
    height: 100%;

    display: ${(props) => props.loaded ? `block` : `none`};
`;

const About = () => {
    const intl = useIntl();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const handleLoad = () => {
        setLoaded(true);
    }

    return (
        <>
            <Helmet>
                <title>About | Fruškogorski vinogradi</title>
                <meta name="description" content="Saznajte više o vinariji Fruškogorski vinogradi, poznatoj po vrhunskim vinima serija Quet, Tri Sunca i Fruskac. Naša vina sa Fruške gore kombinuju tradiciju i inovaciju, pružajući autentičan doživljaj prirode i kvaliteta." />
            </Helmet>
            <Wrapper>
                <picture onLoad={handleLoad}>
                    <source
                        srcSet={`${CoverImage}`}
                        media='(min-width: 768px)'
                    />
                    <source
                        srcSet={`${CoverImageMobile}`}
                        media='(max-width: 767px)'
                    />
                    <CoverImageStyled
                        src={`${CoverImage}`}
                        alt={'A lot of quet wine bottles'}
                        loaded={loaded}
                    />
                </picture>
                <ImageSkeleton loaded={loaded} />
            </Wrapper>
            <FlexBox>
                <Text>{intl.formatMessage({ id: 'about__description', defaultMessage: 'Missing translation' })}</Text>
            </FlexBox>
        </>
    );
}

export default About;